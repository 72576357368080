import React, {FC, useEffect, useMemo, useState} from 'react';
import Panel from "../../components/Panel/Panel";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {ADD_GOLD, ADD_ROCKET, ADD_TTC, DefaultStateType, SET_AD_TIMER, SET_MY_POSITION, SET_RATE, SET_STAGE, getDispatchObject} from "../../store/reducer";
import {useNavigate} from "react-router-dom";
import Icon24ChevronRight from '../../assets/icons/Icon24ChevronRight';
import { Button } from '@nextui-org/react';
import useModal from '../../hooks/useModal';
import { MODAL_AD, MODAL_EXCHANGE, MODAL_WALLET_INPUT, MODAL_WITHDRAW, ROUTE_ACHIEVEMENTS, ROUTE_MINING, ROUTE_PREMIUM, ROUTE_PRIVATE_SALE, SNACKBAR_ERROR, SNACKBAR_SUCCESS } from '../../routes';
import { formatNumberWithSpacesShort } from "../../utils/mathUtils";
import { fetchData } from '../../utils/api';
import Icon48Users from '../../assets/icons/Icon48Users';
import './Profile.css'
import Icon24Ad from '../../assets/icons/Icon24Ad';
import { AdController } from '../../utils/ads';
import dayjs from 'dayjs';
import { getLongTimeLeft } from '../../utils/utils';
import Icon22Premium from '../../assets/icons/Icon22Premium';
import Icon24CBC from '../../assets/icons/Icon24CBC';
import useSnackbar from '../../hooks/useSnackbar';

const adController = new AdController()

//@ts-ignore
const tg = window['Telegram'].WebApp;

const Profile: FC = () => {

    const navigate = useNavigate();

    const selector = useSelector((s: DefaultStateType) => s);
    const dispatch = useDispatch()
    const {t} = useTranslation();
    const {activeModal, setActiveModal, activeModalParams} = useModal();
    const {setActiveSnackbar} = useSnackbar();
    const exchangeRate = useSelector((s: DefaultStateType) => s.exchangeRate);
    const stage = useSelector((s: DefaultStateType) => s.stage);
    const profile = useSelector((s: DefaultStateType) => s.myPosition)
    const lastAd = useSelector((s: DefaultStateType) => s.lastAdWatch)

    const achievements = useSelector((s: DefaultStateType) => s.achievementsList)
    const walletAddress = useSelector((s: DefaultStateType) => s.walletAddress)

    const [timePassed, setTimePassed] = useState(0)
    const [lockAd, setLockAd] = useState(true)
    const premium = useSelector((s: DefaultStateType) => s.premium)
    const [timeLeft, setTimeLeft] = useState<string | null>(premium && premium.active ? getLongTimeLeft(premium.until, t('dayShort')) : null)

    const photo = useMemo(() => {
        return profile ? profile.photo : null
    }, [profile])

    useEffect(() => {
        if (!profile) {
            fetchMyLastWinnings()
        }
    }, [])

    useEffect(() => {
    
        const interval = setInterval(() => {

            const lastAdRewardDate = lastAd ? dayjs(lastAd) : null;

            if (!lastAdRewardDate) {
                setLockAd(false)
            }

            const now = dayjs()
            setTimePassed(now.diff(lastAdRewardDate, 's'))

            if (now.diff(lastAdRewardDate, 's') <= 60) {
                setLockAd(true)
            } else {
                setLockAd(false)
            }

            if (!premium || !premium.active) {
                if (timeLeft !== null) setTimeLeft(null)
            } else {
                setTimeLeft(getLongTimeLeft(premium.until, t('dayShort')))
            }

        }, 1000);
    
        return () => clearInterval(interval);
    }, [lastAd, lockAd, timePassed]);

    const getLevels = async () => {
        const response = await fetchData('/misc/get_levels');
        if (response.error) return null
        return response.result.reduce((res: any, item: any) => ({ ...res, [item.level]: item.levelName}), {})
    }

    const fetchMyLastWinnings = async () => {
        const levels = await getLevels()

        if (!levels) return

        const response = await fetchData('/prizepool/get_my_position_in_top');
        if (response.error) return

        const result = response.result
        dispatch(getDispatchObject(SET_MY_POSITION, {
            league: levels[result.userLevel],
            id: result.userId,
            position: '100+',
            profit: Number(result.reward),
            name: result.firstName ?? "Anonymous",
            photo: result.avatar ? ((process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL : '') + result.avatar) : null
        }))
    }

    const watchAd = async (onlyClaim: boolean) => {
        const res = await adController.showAd()
        console.log(dayjs().toDate())
        if (res == 'success') {
            try {
                const response = await fetchData('/user/claim_ad_reward')

                const result = response.result
                if (!result) return
                setActiveModal(MODAL_AD, {bonus: result.tokenReward !== 0})
                setLockAd(true)
                dispatch(getDispatchObject(SET_AD_TIMER, dayjs().toDate()))
                if (result.goldReward) dispatch(getDispatchObject(ADD_GOLD, Math.round(result.goldReward)))
                if (result.tokenReward !== 0) dispatch(getDispatchObject(ADD_TTC, result.tokenReward))

            } catch (err) {
                console.log(err)
            }
        }
    }

    const openPaymentApp = () => {
        const user = tg?.initDataUnsafe?.user?.id
        const url = `${process.env.REACT_APP_WALLET_URL}${user ? '/?name=' + user : ''}`
        window.location.assign(url)
    }

    const openTransferApp = () => {
        const url = `https://t.me/bunny_announcement/189`
        window.location.assign(url)
    }

    const walletAction = async () => {
        if (walletAddress) {
            try {
                const response = await fetchData('/wallets/withdraw');
                if (response.error) {
                    setActiveSnackbar(SNACKBAR_ERROR, { error: t('withdrawError') })
                    return
                } 
                else if (response.result) {
                    setActiveSnackbar(SNACKBAR_SUCCESS, { message: t('withdrawSuccess') })
                }

            } catch {
                setActiveSnackbar(SNACKBAR_ERROR, { error: t('withdrawError') })
            }

        } else {
            setActiveModal(MODAL_WALLET_INPUT)
        }
    }

    return (
        <Panel scrollable={true} padSides={false} bottomElement={
            <div className='flex w-full px-4'>
                <Button 
                    className='bg-[var(--link\_color)] w-full h-fit rounded-lg py-3 flex gap-2.5 items-center justify-center'
                    onClick={() => watchAd(false)}
                    isDisabled={lockAd}
                >
                    <Icon24Ad />
                    <div className='font-semibold text-lg'>
                        {t('getBonus')}
                    </div>
                </Button>
            </div>
        }>
            <div className='flex flex-col w-full pb-4'>
              <div className='flex flex-col w-full bg-gradient-to-b from-[#19192B00] to-[#19192B] rounded-b-[40px] px-4'>
                <p className='w-full text-white font-medium text-4xl text-center'>
                    Public-Sale
                </p>
                <div className='w-full pt-3 pb-4 relative flex flex-col gap-1'>
                  <p className='font-black text-white text-2xl relative text-center z-40'>
                      1 CBC = {selector.exchangeRate ? selector.exchangeRate : 0.05}$
                  </p>
                  {/* <p 
                      className='text-[var(--secondary\_hint)] text-base font-semibold justify-center flex gap-1 items-center cursor-pointer z-40'
                      onClick={() => navigate(ROUTE_PRIVATE_SALE)}
                  >
                      {t('balanceTokenInfo')}
                      <Icon24ChevronRight />
                  </p> */}
                  <div className='ScreenLoader--ellipse absolute inset-0'/>
                </div>
              </div>
              <div className='px-4 mt-6 flex flex-col w-full'>
                <div className='flex gap-3 w-full'>
                    <div className={[
                        "relative w-[56px] h-[56px] rounded-lg",
                        premium && premium.active ? "RainbowOutline" : (photo === null ? 'border-2 border-[#4F3CC8]' : '')
                    ].join(' ')}>
                        {
                        photo === null ?
                        <div className="w-[52px] h-[52px] flex items-center justify-center">
                            <Icon48Users/> 
                        </div> :
                        <img width={premium ? 52 : 56} height={premium ? 52 : 56} className="LeagueUser--image object-contain h-full rounded-lg" src={photo} alt="" />
                        }
                        {
                            premium && premium.active ? 
                            <div className='absolute top-[-0.25rem] left-[-0.25rem]'>
                                <Icon22Premium />
                            </div> : null
                        }
                    </div>
                    <div className='flex flex-col w-full h-fit gap-1'>
                        <div className='text-sm text-[var(--secondary\_hint)]'>
                            {t('balanceCBC')}
                        </div>
                        <div className='flex w-fit items-baseline'>
                            <p className='text-white text-[28px]/8 font-black'>
                            {Math.round(((Number(selector.ttc) ?? 0) + Number.EPSILON) * (Number(selector.ttc) < 1000 ? 10e4 : 10)) / (Number(selector.ttc) < 1000 ? 10e4 : 10)}
                            </p>
                            <p className='text-[var(--secondary\_hint)] ml-2 font-bold text-lg'>~ ${
                                Number(selector.ttc) < 200 ? +Number((selector.ttc ?? 0) * (exchangeRate ?? 0.05)).toPrecision(2)
                                : Math.round((Number(selector.ttc) ?? 0) * (exchangeRate ?? 0.05))
                            }</p>
                        </div>
                    </div>
                </div>
                <div className='flex w-full'>
                    <Button 
                        className={
                            [
                                'border-1 border-transparent border-solid w-full h-fit rounded-lg py-3 mt-3 flex gap-2.5 items-center justify-center',
                                walletAddress ? 'bg-green-600' : 'bg-[#4F3CC8]'
                            ]
                            .join(' ')
                        }
                        onClick={walletAction}
                    >
                        <div className='font-semibold text-lg'>
                            {t(walletAddress ? 'requestWithdrawal' : 'addWalletButton')}
                        </div>
                    </Button>
                </div>
                <div
                    className='flex flex-row gap-2'
                >
                    <Button 
                        className='bg-white/10 border-[#4B526A] border-solid border-1 w-full h-fit rounded-lg py-3 mt-3 flex gap-2.5 items-center justify-center'
                        onClick={() => openTransferApp()}
                    >
                        <div className='font-semibold text-lg'>
                            {t('balanceTransferCBC')}
                        </div>
                        <Icon24CBC />
                    </Button>
                    <Button 
                        className='bg-white/10 border-[#4B526A] border-solid border-1 w-full h-fit rounded-lg py-3 mt-3 flex gap-2.5 items-center justify-center'
                        onClick={() => openPaymentApp()}
                    >
                        <div className='font-semibold text-lg text-white'>
                            {t('balanceBuyCBC')}
                        </div>
                        <Icon24CBC />
                    </Button>
                </div>
                <div className='flex w-full'>
                    <Button 
                        className='bg-[var(--link\_color)] border-1 border-transparent border-solid w-full h-fit rounded-lg py-3 mt-3 flex gap-2.5 items-center justify-center'
                        onClick={() => navigate(ROUTE_MINING)}
                    >
                        <div className='font-semibold text-lg'>
                            {t('minerProfileButton')}
                        </div>
                        <Icon24CBC />
                    </Button>
                </div>
                <div className='flex w-full py-3 px-4 bg-[#16172F] items-center justify-between rounded-2xl mt-3'>
                    <div 
                        className='flex flex-col w-full gap-1'
                        onClick={() => navigate(ROUTE_ACHIEVEMENTS)}
                    >
                        <div className='text-white text-base font-bold'>
                            {t('myAchievements')}
                        </div>
                        <div className='flex flex-nowrap w-fit gap-1 text-sm font-black'>
                            <div className='text-green-400'>
                                {Object.values(achievements ?? []).filter(item => item.unlocked).length}
                            </div>
                            <div className='text-white'>/</div>
                            <div className='text-white'>
                                {Object.values(achievements ?? []).length}
                            </div>
                        </div>
                    </div>
                    <div className='p-2'>
                        <Icon24ChevronRight />
                    </div>
                </div>
                <div 
                    className='Premium--wrapper rounded-xl mt-3'
                    onClick={() => navigate(ROUTE_PREMIUM)}
                >
                    <div className='Premium--container py-4 rounded-xl p-4 relative overflow-hidden flex w-full justify-between items-start'>
                        <div className='flex flex-col gap-1'>
                            <div className='text-base text-white font-bold'>
                                {t('premiumStatus')}
                            </div>
                            <div className='text-base text-white font-normal max-w-[70%]'>
                                {t('premiumDesc')}
                            </div>
                        </div>
                        <div className='bg-[#FEFCFF4F] rounded-xl p-2 w-fit h-fit backdrop-blur-sm'>
                            <Icon24ChevronRight />
                        </div>
                        {
                            premium && premium.active ? <div className="bg-[#EA3943] absolute right-4 bottom-4 px-1.5 mt-2 text-base font-semibold rounded-xl tracking-wider">
                                {timeLeft}
                            </div> : null
                        }
                    </div>
                </div>
              </div>
            </div>
        </Panel>
    );
};

export default Profile;